import { ConduxService } from "@/services/condux.service";
import { ConduxApiResearcherV1OrderGetResponse, ConduxApiResearcherV1OrdersGetResponse } from "@conduxio/types";

export class OrderService extends ConduxService {
  protected modelNameSingular = "order";
  protected modelNamePlural = "order";

  public read(id: string): Promise<ConduxApiResearcherV1OrderGetResponse> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: false, workspaceId: "" };

    return super.get<ConduxApiResearcherV1OrderGetResponse>(apiRequest);
  }

  public readAll(): Promise<ConduxApiResearcherV1OrdersGetResponse> {
    const apiRequest = { url: this.modelNameSingular, withoutWorkspaceId: false, workspaceId: "" };

    return super.get<ConduxApiResearcherV1OrdersGetResponse>(apiRequest);
  }
}
