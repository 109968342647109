import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiResearcherV1PaymentMethodPaymentIntentPostResponse,
  ConduxApiResearcherV1PaymentMethodPostResponse,
  ConduxApiResearcherV1PaymentMethodsGetResponse,
} from "@conduxio/types";

export class PaymentService extends ConduxService {
  addIntent(data: { fullName: string; email: string; token: string }): Promise<ConduxApiResearcherV1PaymentMethodPaymentIntentPostResponse> {
    const apiRequest = { url: this.modelNamePlural + "payment-methods/intent", withoutWorkspaceId: true, object: { ...data } };

    return super.post<ConduxApiResearcherV1PaymentMethodPaymentIntentPostResponse>(apiRequest);
  }

  getPaymentMethods(): Promise<ConduxApiResearcherV1PaymentMethodsGetResponse> {
    const apiRequest = { url: this.modelNamePlural + "payment-method" };

    return super.get<ConduxApiResearcherV1PaymentMethodsGetResponse>(apiRequest);
  }

  addPaymentMethods(data: { customerId: string; cardId: string; paymentMethodId: string }): Promise<ConduxApiResearcherV1PaymentMethodPostResponse> {
    const apiRequest = { url: this.modelNamePlural + "payment-methods", object: { ...data } };

    return super.post<ConduxApiResearcherV1PaymentMethodPostResponse>(apiRequest);
  }

  deletePaymentMethod(id: string): Promise<ConduxApiResearcherV1PaymentMethodPostResponse> {
    const apiRequest = { url: this.modelNameSingular + "payment-method/" + id, modelId: id };

    return super.delete<ConduxApiResearcherV1PaymentMethodPostResponse>(apiRequest);
  }
}
