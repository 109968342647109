<template>
  <template v-if="havePrivileges">
    <slot></slot>
  </template>
</template>

<script setup lang="ts">
import { globalInstance } from "@/services/globals.service";
import { computed, toRef } from "vue";

const props = defineProps({
  platformOwner: { type: Boolean, default: true },
  workspaceOwner: { type: Boolean, default: false },
  admin: { type: Boolean, default: false },
  researcher: { type: Boolean, default: false },
});

const platformOwner = toRef(props, "platformOwner");
const workspaceOwner = toRef(props, "workspaceOwner");
const admin = toRef(props, "admin");
const researcher = toRef(props, "researcher");

const havePrivileges = computed((): boolean => {
  const roles = globalInstance.workspaceRoles.map((i) => i.name);
  const result: boolean[] = [];

  if (platformOwner.value) {
    result.push(roles.map((i) => i.toLocaleLowerCase()).includes("platform owner"));
  }

  if (workspaceOwner.value) {
    result.push(roles.map((i) => i.toLocaleLowerCase()).includes("workspace owner"));
  }

  if (admin.value) {
    result.push(roles.map((i) => i.toLocaleLowerCase()).includes("admin"));
  }

  if (researcher.value) {
    result.push(roles.map((i) => i.toLocaleLowerCase()).includes("researcher"));
  }

  return result.includes(true);
});
</script>
