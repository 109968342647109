import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1ResearcherInviteLinkPostBody,
  ConduxApiResearcherV1ResearcherInviteLinkPostResponse,
  ConduxApiResearcherV1ResearcherInviteEmailLinkPostBody,
  ConduxApiResearcherV1ResearcherInviteEmailLinkPostResponse,
  ConduxApiResearcherV1ResearcherHubspotRequest,
  ConduxApiResearcherV1ResearcherHubspotPostResponse,
} from "@conduxio/types";

export class RegisterService extends ConduxService {
  protected modelNameSingular = "register";
  protected modelNamePlural = "register";

  public generateLink(request: ConduxApiResearcherV1ResearcherInviteLinkPostBody): Promise<ConduxApiResearcherV1ResearcherInviteLinkPostResponse> {
    const apiRequest = { url: "invite-link-post", workspaceId: "", object: request, modelName: "link" };

    return super.post<ConduxApiResearcherV1ResearcherInviteLinkPostResponse>(apiRequest);
  }

  public sendInvite(
    request: ConduxApiResearcherV1ResearcherInviteEmailLinkPostBody
  ): Promise<ConduxApiResearcherV1ResearcherInviteEmailLinkPostResponse> {
    const apiRequest = { url: "invite-email-link", workspaceId: "", object: request, modelName: "invite email link" };

    return super.post<ConduxApiResearcherV1ResearcherInviteEmailLinkPostResponse>(apiRequest);
  }

  public ownerSubscribe(request: ConduxApiResearcherV1ResearcherHubspotRequest): Promise<ConduxApiResearcherV1ResearcherHubspotPostResponse> {
    const apiRequest = { url: "researcher/hubspot-registration", object: request, modelName: "owner subscription" };

    return super.post<ConduxApiResearcherV1ResearcherHubspotPostResponse>(apiRequest);
  }
}
