<template>
  <template v-if="hasToBeShown && show">
    <!-- mimetic version -->
    <div v-if="mimetic" class="mimetic"><slot></slot> dev&nbsp;</div>

    <!-- development explicit version -->
    <div v-if="!mimetic" class="banner">
      <v-expansion-panels v-model="panels" variant="accordion" elevation="0" class="mb-0">
        <v-expansion-panel class="controls-justify-left">
          <v-expansion-panel-title>
            <!-- header -->
            <div>
              <v-icon class="me-1">mdi mdi-code-tags</v-icon>
              <span class="panel-title">
                <slot name="title">Developer Mode Content</slot>
              </span>
            </div>
            <v-icon class="ms-auto text-right" v-if="false" @click.stop="show = false">mdi mdi-close</v-icon>
          </v-expansion-panel-title>

          <!-- content -->
          <v-expansion-panel-text class="px-4 mx-6">
            <v-card class="mt-2 flat">
              <v-card-text>
                <slot></slot>
              </v-card-text>
            </v-card>

            <!-- disclaimer -->
            <v-divider class="line mt-4 mb-1" />
            <div class="text-center opacity-60">
              <i
                ><small>{{ disclaimer }}</small></i
              >
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </template>
</template>

<script lang="ts">
/**
 * base/DeveloperContainer
 * This component is responsible to render a content visible only in development mode (localhost/develop)
 *
 * HOW TO USE:
 * 1. Import the component
 *    import DevelopContainer from "@/components/base/DeveloperContainer.vue";
 * 
 * 2. Register the component
      components: {
        DevelopContainer,
      }
 * 3. Use the component 
      <develop-container :v-model="true" :mimetic="false" :allowTestStage="true"  :allowUatStage="true">
        <template #title>YOUR TITLE</template>
        <template #default>
          YOUR CONTENT HERE
        </template>
      </develop-container>
 */
import { defineComponent } from "vue";

export default defineComponent({
  name: "DeveloperContainer",
  setup() {
    return {};
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    mimetic: {
      // true to disguise the component
      type: Boolean,
      default: false,
    },
    allowTestStage: {
      // True to allow the component to be visible in the Test stage
      type: Boolean,
      default: false,
    },
    allowUatStage: {
      // True to allow the component to be visible in the UAT stage
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  // #region Properties
  data() {
    return {
      show: this.modelValue,

      // UI
      panels: [0],
      panelStatus: false,
    };
  },
  computed: {
    stageName(): string {
      return import.meta.env.VITE_APP_ENV_NAME;
    },
    /**
     * Returns if the component should be visible, depending on the Stage or the Development mode
     */
    hasToBeShown(): boolean {
      // Returns if the app is in development, Develop Stage, or Test Stage (if allowed), or UAT Stage (if allowed)
      return (
        import.meta.env.VITE_APP_DEVELOPMENT === "true" ||
        (this.allowTestStage && this.stageName === "test") ||
        (this.allowUatStage && this.stageName === "uat")
      );
    },
    disclaimer(): string {
      const stages: string[] = ["Develop"];
      if (this.allowTestStage) stages.push("Test");
      if (this.allowUatStage) stages.push("U.A.T.");

      return `This section is visible only to Developers and in the ${stages.join(", ")} stage${stages.length > 1 ? "s" : ""}.`;
    },
  },
  watch: {
    // Sync props
    modelValue(newValue: boolean) {
      this.show = newValue;
    },
    show(newValue: boolean) {
      this.$emit("update:modelValue", newValue);
    },
  },
});
</script>

<style scoped>
div.banner {
  background-color: rgba(255, 255, 204, 0.5);
  border-radius: 4px;
  padding: 16px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
div.mimetic {
  background-color: rgba(255, 255, 204, 0.5);
  border-radius: 4px;
  padding: 0px;
  margin-left: 4px;
  display: inline-block;
  font-size: 0.75em;
  color: #c0c0c0;
  text-align: right;
}
.v-expansion-panel {
  background-color: transparent;
}
span.panel-title {
  font-weight: bold;
}
</style>
