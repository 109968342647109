/*
  ./src/router/
  Frontend router
**/
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { globalInstance } from "@/services/globals.service";
import { setResearcherServiceInstance, ResearcherService } from "@/services/researcher.service";

/*
  Date    2023-06-15
  Author  Simone
  Task    "Retore and Improve refreshToken functionallity" https://app.clickup.com/t/863gzuva1
  Desc    since we cannot restore the previous usage of the ResearcherService.getResearcherServiceInstance()
          this seems to be the only way we can refer to the researcherService instance, to access the refreshToken functionality
**/
const researcherServiceInstance = new ResearcherService(globalInstance);

setResearcherServiceInstance(researcherServiceInstance);

// #region Routes
const routes: RouteRecordRaw[] = [
  // #region No logged
  {
    path: "/login",
    name: "LoginForm",
    redirect: { name: "Auth" },
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("@/modules/auth/views/index.vue"),
    meta: {
      title: "Auth",
      requiredPrivileges: [],
      allowHosts: true,
      emptyLayout: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/modules/auth/views/Register.vue"),
    meta: {
      title: "Sign Up",
      requiredPrivileges: [],
      allowHosts: true,
      emptyLayout: true,
    },
  },
  {
    // Google Federated Signin redirect_uri
    path: "/federated-signin/google",
    name: "FederatedSigninGoogle",
    component: () => import("@/views/NotLogged/FederatedSignin.vue"),
    meta: {
      title: "Signing in with Google",
      requiredPrivileges: [],
    },
  },
  {
    // Microsoft Federated Signin redirect_uri
    path: "/federated-signin/microsoft",
    name: "FederatedSigninMicrosoft",
    component: () => import("@/views/NotLogged/FederatedSignin.vue"),
    meta: {
      title: "Signing in with Microsoft",
      requiredPrivileges: [],
    },
  },
  {
    path: "/terms_of_service",
    name: "TermsOfService",
    component: () => import("@/views/NotLogged/TermsOfService.vue"),
    meta: {
      title: "Terms of Service",
      requiredPrivileges: [],
      allowHosts: true,
      emptyLayout: true,
    },
  },
  {
    path: "/privacy_policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/NotLogged/PrivacyPolicy.vue"),
    meta: {
      title: "Privacy Policy",
      requiredPrivileges: [],
      allowHosts: true,
      emptyLayout: true,
    },
  },
  {
    path: "/cookie_policy",
    name: "CookiePolicy",
    component: () => import("@/views/NotLogged/CookiePolicy.vue"),
    meta: {
      title: "Cookie Policy",
      requiredPrivileges: [],
      allowHosts: true,
      emptyLayout: true,
    },
  },
  // #endregion No logged
  {
    path: "/home",
    name: "Home",
    redirect: { name: "Dashboard" },
  },
  {
    path: "/workspacehub",
    name: "WorkspaceHub",
    component: () => import("@/views/WorkspaceHub/WorkspaceHub.vue"),
    meta: {
      title: "Settings",
      requiredPrivileges: ["settings_read", "settings_update"],
      requiredRoles: ["Admin", "Workspace Owner"],
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Home/Dashboard.vue"),
    meta: {
      title: "Dashboard",
      requiredPrivileges: ["project_read", "study_read"],
    },
  },
  {
    path: "/select-workspace",
    name: "Workspace",
    component: () => import("@/views/Workspace/SelectWorkspace.vue"),
    meta: {
      title: "Workspace",
      requiredPrivileges: ["workspace_read"],
    },
  },
  {
    path: "/availability",
    name: "Availability",
    component: () => import("@/views/Schedules/Availability.vue"),
    meta: {
      title: "Add a Schedule",
      requiredPrivileges: ["schedule_read"],
    },
  },
  // #region Studies
  {
    path: "/studies",
    name: "Studies",
    component: () => import("@/views/Studies/Studies.vue"),
    meta: {
      title: "Studies",
      requiredPrivileges: ["study_read"],
    },
  },
  {
    path: "/project/:projectId/study/:id",
    name: "StudyView",
    component: () => import("@/views/Studies/StudyView.vue"),
    meta: {
      title: "Study",
      requiredPrivileges: ["study_read"],
    },
  },
  {
    path: "/project/:projectId/studyV2/:id",
    name: "StudyV2",
    component: () => import("@/views/Studies/StudyV2.vue"),
    meta: {
      title: "Study",
      requiredPrivileges: ["study_read"],
    },
  },
  {
    path: "/media",
    name: "Media",
    component: () => import("@/views/Media/Media.vue"),
    meta: {
      title: "Media",
      requiredPrivileges: ["image_read"],
    },
  },
  // #endregion Studies
  // #region Account
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/Account/Account.vue"),
    meta: {
      title: "Account",
      requiredPrivileges: ["oauth_account_read"],
    },
  },
  {
    // when zoom redirects to the frontend after signin, with ?code=xxxx
    /*  IMPORTANT: DO NOT CHANGE THIS ROUTE
        this is the only redirect_uri allowed by 3rd-party applications (zoom) to perform redirect after the signin **/
    path: "/account/zoom-account/add",
    name: "AccountZoomAdd",
    component: () => import("@/views/Account/Components/ZoomAccount.vue"),
    meta: {
      title: "Connecting to Zoom...",
      requiredPrivileges: ["oauth_account_read"],
    },
  },
  {
    // when gmail/office redirects to the frontend after signin, with ?code=xxxx
    /*  IMPORTANT: DO NOT CHANGE THIS ROUTE
          this is the only redirect_uri allowed by 3rd-party applications (gmail and office365) to perform redirect after the signin **/
    path: "/account/calendar/:calendarType(gmail|office365)/add",
    name: "AccountCalendarAdd",
    component: () => import("@/views/Account/Components/Calendar.vue"),
    meta: {
      title: "Connecting to a Calendar...",
      requiredPrivileges: ["oauth_account_add"],
    },
  },
  // #endregion Account
  // #region People
  {
    path: "/participants",
    name: "Participants",
    component: () => import("@/views/People/Participants.vue"),
    meta: {
      title: "Participants",
      requiredPrivileges: ["researcher_read", "researcher_update"],
    },
  },
  {
    path: "/participant/:id",
    name: "Participant",
    component: () => import("@/views/People/ParticipantDetails.vue"),
    meta: {
      title: "Participant",
      requiredPrivileges: ["researcher_read", "researcher_update"],
    },
  },
  // #endregion People
  // #region Workspace Owner
  {
    path: "/trash",
    name: "Trash",
    component: () => import("@/views/WorkspaceOwner/Trash.vue"),
    meta: {
      title: "Trash",
      requiredPrivileges: ["settings_read"],
    },
  },
  // #endregion Workspace Owner
  {
    path: "/study/:id/survey",
    name: "Survey",
    component: () => import("@/views/Surveys/SurveyV2.vue"),
    meta: {
      title: "Survey Study",
      requiredPrivileges: ["survey_read", "survey_update"],
    },
  },
  {
    path: "/project/:projectId/study/:id/session/:sessionId",
    name: "SessionPlayback",
    component: () => import("@/views/Interview/Playback.vue"),
    meta: {
      title: "Session Playback",
      requiredPrivileges: ["project_update"], // temporary required login to access the playback page
    },
  },
  // #region Participant
  {
    path: "/participant/study/:id/survey",
    name: "ParticipantSurvey",
    component: () => import("@/participant/views/SurveyStudy/SurveyComponent.vue"),
    meta: {
      title: "Survey",
      requiredPrivileges: [],
      allowHosts: true,
    },
  },
  // #endregion Participant
  {
    path: "/wrong",
    name: "WrongPage",
    component: () => import("@/views/WrongPage.vue"),
    meta: {
      title: "Wrong Page",
      requiredPrivileges: [],
      allowHosts: true,
    },
  },

  // #region Platform Owner
  {
    path: "/platform-owner/subscriptions",
    name: "Platform Owner Subscriptions",
    component: () => import("@/views/PlatformOwner/Subscriptions.vue"),
    meta: {
      title: "Subscriptions",
      requiredPrivileges: [],
      requiredRoles: ["Platform Owner"],
    },
  },
  // #endregion Platform Owner

  // This catchall MUST be at the end of the routes
  {
    path: "/:catchAll(.*)",
    redirect: { name: globalInstance.isLoggedIn ? "Home" : "Auth" },
  },
];
// #endregion Routes

// Creates the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// #region Navigation Guard
router.beforeEach(async (to, from, next) => {
  if (globalInstance.isLoggedIn && globalInstance.userWorkspace) {
    const lastUrl = globalInstance.lastUrls.find(
      (l) => l.userId === globalInstance.loggedInUser.id && l.workspaceId === globalInstance.userWorkspace.id
    );
    if (lastUrl) {
      lastUrl.url = to.fullPath;
    } else {
      globalInstance.lastUrls.push({
        userId: globalInstance.loggedInUser.id as string,
        workspaceId: globalInstance.userWorkspace.id as string,
        url: to.fullPath,
      });
    }
  }

  // Get first matching privileges                // in case we need to get the query parameters
  const requiredPrivileges: string[] = []; // list of the route's required privileges, if set
  const requiredRoles: string[] = [];
  let routedDisabled; // we can disable any route, by adding route.meta.disabled = true
  let routeAllowHosts; // we can tell a route is always allowed to be navigated, event if the user's not logged-in, by adding route.meta.allowHosts = true

  // to.matched is an array of RouteRecordNormalized
  // representing the routes in the RouteRecordRaw array matching the current "to" (destination) route
  for (const matchedRoute of to.matched) {
    // we can disable any route, by adding route.meta.disabled = true
    // we can tell a route is always allowed to be navigated, event if the user's not logged-in, by adding route.meta.allowHosts = true
    const { disabled, allowHosts } = matchedRoute.meta;

    routedDisabled = disabled;
    routeAllowHosts = allowHosts;

    if (matchedRoute.meta.requiredRoles !== null && Array.isArray(matchedRoute.meta.requiredRoles) && matchedRoute.meta.requiredRoles.length > 0) {
      requiredRoles.push(...matchedRoute.meta.requiredRoles);
    }

    if (
      matchedRoute.meta.requiredPrivileges !== void 0 &&
      Array.isArray(matchedRoute.meta.requiredPrivileges) &&
      matchedRoute.meta.requiredPrivileges.length > 0
    ) {
      requiredPrivileges.push(...matchedRoute.meta.requiredPrivileges);
      break;
    }
  }

  // If the required route is disabled, rdirects to the Home
  if (routedDisabled) return next({ name: "Home" });

  // #region RefreshToken Hard Test
  // ------ DO NOT REMOVE THIS BLOCK OF CODE ------
  // this is meant to test the tokenRefresh functionality in case something more will happen on the token functions
  // const refreshTokenResultX = await researcherServiceInstance.tokenRefresh({ refreshToken: globalInstance.userRefreshToken })

  // #endregion RefreshToken Hard Test

  // Checks privileges of roles
  // see this excel file: https://docs.google.com/spreadsheets/d/1_6xDlXL3Ym6dDVz_KGwq4jMIXzCGr_6g2XoioCoIEuw/edit?pli=1&gid=0#gid=0
  // i check if he is a platform owner. If he is a platform owner, it will skip this check, platform owner can do anything
  if (requiredRoles.length > 0 && !globalInstance.workspaceRoles.find((i) => i.name == "Platform Owner")) {
    const requiredRolesToLower = requiredRoles.map((i) => i.toLocaleLowerCase());
    const hasPrivileges =
      globalInstance.workspaceRoles.map((i) => requiredRolesToLower.includes(i.name.toLocaleLowerCase())).find((i) => i == true) || false;

    if (!hasPrivileges) {
      next({ name: "Home" });
    }
  }
  //  Checks if the route requires privileges
  if (requiredPrivileges.length > 0) {
    if (globalInstance.isLoggedIn) {
      // User is logged In
      // needs to choose workspace
      if (!globalInstance.isWorkspaceSet && to.name !== "Workspace") {
        // return next('select-workspace?ciao=aaaa')
        return next();
      } else {
        // Checks if the user has the required privileges: we do allow them to navigate that route
        if (globalInstance.hasWorkspacePrivileges(requiredPrivileges)) {
          return next();
        } else {
          // logged in user DOESN'T have the required privileges: we redirect them to home
          return next({ name: "Home" });
        }
      }
    } else {
      // The user is not logged in, and the required route requires privileges: we redirect them to the Auth
      return next({ name: "Auth" });
    }
  } else {
    // This route DOESN'T requires privileges
    // if the user's not logged, and the route DOESN'T allow host, we redirect them to home
    if (globalInstance.isLoggedIn && routeAllowHosts === false) return next({ name: "Home" });
    return next();
  }
});

router.afterEach((to) => {
  // Sets document title as route meta->title
  document.title = import.meta.env.VITE_APP_NAME + (to.meta.title ? " - " + String(to.meta.title) : "");
});
// #endregion Navigation Guard

export default router;
