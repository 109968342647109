<template>
  <v-navigation-drawer
    v-if="showNavControls"
    v-model="drawer"
    class="b-green c-light_gray left-menu"
    :permanent="$vuetify.display.mdAndUp"
    :width="240"
  >
    <v-list>
      <v-list-item to="/home">
        <img :src="importLogo()" class="logo" />
      </v-list-item>
      <v-list-item class="d-flex">
        <WorkspaceSelect />
      </v-list-item>
    </v-list>
    <v-divider class="my-2" />
    <!-- main menu -->
    <v-list nav class="c-light_gray">
      <!-- menu for every other role -->
      <FilterRoles :platform-owner="true" :workspace-owner="true" :admin="true" :researcher="true">
        <template v-for="item in mainMenu.filter((m) => m.visible && !m.bottom)" :key="item">
          <v-list-item
            v-if="!item.children && item.visible"
            :prepend-icon="item.icon"
            :title="item.title"
            link
            :to="item.url"
            active-class="b-mint c-green"
          />
          <!-- father items -->
          <v-list-group v-if="item.children && item.visible">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="item.title" :prepend-icon="item.icon" />
            </template>
            <!-- child items -->
            <template v-for="child in item.children" :key="child">
              <v-list-item v-if="child.visible" :title="child.title" :to="child.url" active-class="b-mint c-green" />
            </template>
          </v-list-group>
        </template>
      </FilterRoles>

      <!-- menu for Platform Owner -->
      <FilterRoles :platform-owner="true" :workspace-owner="false" :admin="false" :researcher="false">
        <template v-for="item in platformOwnerMenu.filter((m) => m.visible && !m.bottom)" :key="item">
          <v-list-item
            v-if="!item.children && item.visible"
            :prepend-icon="item.icon"
            :title="item.title"
            link
            :to="item.url"
            active-class="b-mint c-green"
          />
          <!-- father items -->
          <v-list-group v-if="item.children && item.visible">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="item.title" :prepend-icon="item.icon" />
            </template>
            <!-- child items -->
            <template v-for="child in item.children" :key="child">
              <v-list-item v-if="child.visible" :title="child.title" :to="child.url" active-class="b-mint c-green" />
            </template>
          </v-list-group>
        </template>
      </FilterRoles>
    </v-list>

    <!-- other things-->
    <template #append>
      <v-list nav>
        <FilterPlan
          v-if="!Number.isNaN(getFreeTrialExpire) && plan.trial && parseFloat(getFreeTrialExpire) > 0"
          :basic="false"
          :standard="true"
          :enterprise="false"
        >
          <div class="banner mb-4">
            <div class="d-flex justify-space-between mb-3">
              <div>Free trial</div>
              <div>{{ getFreeTrialExpire }} day left</div>
            </div>
            <v-progress-linear
              :model-value="100 - (Number(getFreeTrialExpire) / 15) * 100"
              :height="8"
              buffer-color="white"
              buffer-value="100"
              color="green"
              style="border-radius: 10px"
            ></v-progress-linear>
          </div>
        </FilterPlan>
        <!-- TODO: restore when we're ready for upgrading: basic=true standard=true enterprise=false -->
        <FilterPlan :basic="false" :standard="false" :enterprise="false">
          <div class="banner mb-4">
            <img :src="importLamp()" class="plan-lamp" alt="ConduxLamp" />
            <h3 class="plan-title">Upgrade Your Plan</h3>
            <p class="plan-description">
              You're on the {{ capitalize(planName) }} Plan with limited features. Unlock advanced tools and AI insights by upgrading to
              <template v-if="planName != 'standard'">Standard or</template> Enterprise.
            </p>
            <v-btn variant="elevated" class="w-100" color="white">Upgrade Now</v-btn>
          </div>
        </FilterPlan>
        <v-list-item
          title="Report an Issue"
          prepend-icon="mdi mdi-help-circle-outline"
          link
          :href="supportLink"
          target="_blank"
          active-class="b-mint c-green"
          class="mb-4"
        />
        <FilterRoles :workspace-owner="true" :admin="true">
          <v-list-item
            v-for="item in mainMenu.filter((m) => m.visible && m.bottom)"
            :key="item.title"
            :prepend-icon="item.icon"
            :title="item.title"
            link
            :to="item.url"
            active-class="b-mint c-green"
            class="mb-4"
          />
        </FilterRoles>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
//imports menu items
import mainMenu from "../../static/menu/main-menu";
import platformOwnerMenu from "../../static/menu/platform-owner-menu";
import WorkspaceSelect from "@/layouts/Components/WorkspaceSelect.vue";
import FilterRoles from "@/components/base/FilterRoles.vue";
import { capitalize } from "lodash";
import FilterPlan from "@/components/base/FilterPlan.vue";
import { ResearcherService } from "@/services/researcher.service";

export default defineComponent({
  name: "MainMenu",
  components: {
    WorkspaceSelect,
    FilterRoles,
    FilterPlan,
  },
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  mounted() {
    // const res = await this.researcherService.getSubscription();
    const res = this.$globalsService.workspaceSubscriptionPlan;
    const { trial, expiresAt } = res;

    this.plan = { trial, expiresAt };
  },
  setup() {
    const researcherService = inject<ResearcherService>("researcherService") as ResearcherService;

    return { mainMenu, platformOwnerMenu, capitalize, researcherService };
  },
  // #region Properties
  data() {
    return {
      plan: {
        trial: false,
        expiresAt: "",
      },
      drawer: this.modelValue,
      supportLink:
        "https://condux-dot-yamm-track.appspot.com/2CZMJCcm9Rofuqhosl2NdJECXN_MnbIjcPOIWDjsCnnr8SHNDkgGckRefpPD5X_2C-okkz31o4xWjCCPNuRZt4sG9PO0tVandFSUUx7VJJF7QRdXamsS6CxPjsnQjZdjEdep_cpiZ2RTnzDtqJRXIEyNv7jqghP874u0d1aZyLKUyIClFdDcDC9e4oN8vwmxtAzUfUvJn1p3a",
      planName: this.$globalsService.workspacePlan,
    };
  },
  computed: {
    // Returns release name from env
    release(): string {
      return `${import.meta.env.VITE_APP_RELEASE}`;
    },
    showNavControls(): boolean {
      /*
        Date    2023-06-08
        Author  Simone
        Task    "Navigation controls show during a login or registration" https://app.clickup.com/t/863gyezw6
      **/
      // Returns false if, for some reason, we have to hide navigation controls on the current page
      // This is needed because:
      //  during a login a registration or something like that, the left-bar and the other controls
      //  in the top-bar used to be shown after the API call before the user redirection though.
      //  This makes sure these controls are hidden until a complete redirect.
      switch (this.$route.name) {
        case "RegisterHubspot":
        case "LoginForm":
        case "ParticipantSurvey":
          return false;
        default:
          return true;
      }
    },
    getFreeTrialExpire(): string {
      const today = new Date();
      const freeTrialExpire = new Date(this.plan.expiresAt);

      const Difference_In_Time = freeTrialExpire.getTime() - today.getTime();
      const Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

      return Difference_In_Days.toString();
    },
  },
  // #endregion Properties

  watch: {
    drawer: function (newVal) {
      this.$emit("update:modelValue", newVal);
    },
    modelValue: function (newVal) {
      this.drawer = newVal;
    },
  },
  methods: {
    importLogo: function (): string {
      return new URL("@/assets/images/logo/logo.png", import.meta.url).href;
    },
    importLamp(): string {
      return new URL(`/src/assets/images/logo/lamp.png`, import.meta.url).href;
    },
  },
  // #endregion Properties
});
</script>

<style lang="scss">
.left-menu {
  z-index: 1101 !important;
  .v-list-item {
    color: white;
  }
}

img.logo {
  width: 200px;
}

span.release {
  position: absolute;
  right: 8px;
  bottom: 0;
  font-size: 0.6em;
  opacity: 0.3;
  text-transform: uppercase;
  font-weight: bold;
}

img.logo {
  width: 200px;
}

span.release {
  position: absolute;
  right: 8px;
  bottom: 0;
  font-size: 0.6em;
  opacity: 0.3;
  text-transform: uppercase;
  font-weight: bold;
}

.banner {
  background-color: rgba($color: #f7faf71f, $alpha: 0.12);
  padding: 11px 22px;
  font-size: 14px;
  border-radius: 4px;
}

.banner > * {
  margin: 8px 0px;
}

.banner > img {
  width: 30px;
}

.plan-description,
.plan-lamp {
  display: none;
}

.plan-title {
  display: flex;
  justify-content: center;
}

@media screen and (min-height: 965px) {
  .plan-description,
  .plan-lamp {
    display: block;
  }

  .plan-title {
    display: block;
  }
}
</style>
