import { ConduxService } from "@/services/condux.service";
import { ConduxApiSubscriptionPlansV1SubscriptionPlansGetResponse } from "@conduxio/types";

export class PlansService extends ConduxService {
  urlPathPrefix = "/subscription_plans/v1/";

  /**
   * Lists all subscription plans
   * @returns Promise<ConduxApiSubscriptionPlansV1SubscriptionPlansGetResponse>
   */
  getAll(): Promise<ConduxApiSubscriptionPlansV1SubscriptionPlansGetResponse> {
    const apiRequest = { url: "subscription_plans" };

    return super.get<ConduxApiSubscriptionPlansV1SubscriptionPlansGetResponse>(apiRequest);
  }
}
