export default {
  required: (v: string): any => !!v || "Field Required.",
  ratingRequired: (v: number): any => {
    console.log(v);
    return v > 0 || "Field Required.";
  },
  required_array: (v: string[]): any => (v && v.length > 0) || "Field Required.",
  requiredFile: (v: string): any => (!!v && v.length > 0) || "Field Required.",
  requiredWithParam(v: string): any {
    return !!v || "Field Required.";
  },
  email: (v: string): any => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // if input is empty we dont need to validate
    // you can use "required" method for checking empty value
    if (!v) return true;

    return pattern.test(v) || "Invalid e-mail.";
  },
  url: (v: string): any => {
    const urlR = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    if (!v) return true;

    return urlR.test(v) || "Invalid url";
  },
  min(num: number): any {
    return (v: string) => (v || "").length >= num || `Min ${num} characters`;
  },
  minNumber(num: number, customErrorMess: string = `Min value is ${num}`): any {
    return (v: number) => v >= num || (v as any) === "" || customErrorMess;
  },
  minWithVal(num: number): any {
    return (v: string) => {
      (v || "").length >= num || `Min ${num} characters`;
    };
  },
  minWithParam(v: string, num: number): any {
    return (v || "").length >= num || `Min ${num} characters`;
  },
  max(num: number): any {
    return (v: string) => (v || "").length <= num || `Max ${num} characters`;
  },
  maxNumber(num: number): any {
    return (v: number) => v <= num || `Max value is ${num}`;
  },
  upper: (v: string): any => {
    const pattern = /[A-Z]{1}/;

    return pattern.test(v) || "At least one uppercase character";
  },
  lower: (v: string): any => {
    const pattern = /[a-z]{1}/;

    return pattern.test(v) || "At least one lowercase character";
  },
  digit: (v: string): any => {
    const pattern = /\d/;

    return pattern.test(v) || "At least one digit";
  },
  special: (v: string): any => {
    const pattern = /[^A-Za-z0-9]/;

    return pattern.test(v) || "At least one special character";
  },
  otp(v: string): any {
    // Validates MFA OTP
    const pattern = /^\d{6}|\d{8}$/;

    return pattern.test(v) || "OTP must be 6 or 8 digits";
  },
  matchingPasswords(field: string): any {
    // rule to check if a password matches the confirmation password
    return (v: string) => v === field || "Password does not match";
  },
  dateMajorOfCurrent: (start: string): any => {
    const DateStart = new Date(start);
    const CurrentDate = new Date();

    CurrentDate.setHours(0, 0, 0, 0);

    return DateStart >= CurrentDate || "Date must be greater or equal then current date";
  },
  dateHigherThan(start: string): any {
    // rule to check if start date is higher or equal to the given (compareDate) date
    return (compareDate: string) => {
      const DateStart = new Date(start);
      const DateEnd = new Date(compareDate);

      return !start || !compareDate || DateEnd > DateStart || "Date must be greater than " + DateStart.toDateString();
    };
  },
  sessionDateHigherThan(start: string): any {
    // rule to check if start date is equal to or after the current date
    return (compareDate: string) => {
      const DateStart = new Date(start);

      if (compareDate.split("T")[1].length == 5) {
        compareDate += ":00.000Z";
      }

      if (!start || !compareDate) {
        return "Start date and compare date must be provided.";
      }

      return DateStart.toISOString() <= compareDate || "The compare date must be equal to or after the start date: " + DateStart.toDateString();
    };
  },
  dateHigherOrEqual: (start: Date): any => {
    // rule to check if start date is higher or equal to today's date

    // We need to remove offset because it broke the data
    // example -> we pass in the function 20/10/2024 and we are in a zone with -6 utc
    // so 20/10/2024 -> 19/10/2024 18:00:00.0000
    const aus = new Date(start);
    const userTimezoneOffset = aus.getTimezoneOffset() * 60000;
    const DateStart = new Date(aus.getTime() + userTimezoneOffset);
    //

    const CurrentDate = new Date();

    CurrentDate.setHours(0, 0, 0, 0);

    return DateStart >= CurrentDate || "Date must be greater or equal to current date";
  },
  custom: (condition: boolean, message: string): any => {
    return condition || message;
  },

  tags: (tags: string[]): any => {
    if (tags.length > 3) {
      return "Maximum number of tags is 3";
    }
    for (const tag of tags) {
      if (tag.length > 12) {
        return `Invalid tag ${tag}, max chars number is 12`;
      }
      const pattern = /^[A-Za-z0-9\-_]/;

      if (!pattern.test(tag)) {
        return `Invalid tag ${tag}, can only contain these special characters '-'  '_'`;
      }
    }
    return true;
  },
  domainList: (value: string): any => {
    const pattern = /^([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\s*,\s*([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,})*(\s*,\s*)?$/;

    if (!pattern.test(value)) {
      return "be sure to insert a valid list domains separated by a comma";
    }

    return true;
  },
  password: (value: string): true | string => {
    const pattern = /^(?=.*\d)(?=.*[?!-_@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (!pattern.test(value)) {
      return "The password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, and a symbol";
    }

    return true;
  },
  temporaryPassword: (value: string): true | string => {
    const pattern = /^[A-Fa-f0-9]{8}Co\@1$/;

    if (!pattern.test(value)) {
      return "Please copy and paste the OTP exactly as it appears in the email";
    }

    return true;
  },
};
