<template>
  <v-app-bar class="b-light" :elevation="0">
    <!-- mobile app-bar -->
    <v-app-bar-nav-icon v-if="$globalsService.isLoggedIn && showNavControls" variant="text" @click.stop="$emit('toggleLeft')" />
    <template #append>
      <!-- login button -->
      <v-btn v-if="!$globalsService.isLoggedIn && showNavControls" stacked flat color="primary" @click="login"> Login </v-btn>

      <!-- clear workspace -->
      <v-btn
        v-if="$globalsService.loggedInUser && $globalsService.loggedInUser.emailAddress === 'e2e.test@condux.io'"
        data-cy="workspace-clear"
        small
        class="mr-0 pa-0 b-light"
        stacked
        flat
        @click="clearTestWorkspace()"
      >
        <v-icon color="primary">{{ $icons.clear }}</v-icon>
        <v-tooltip activator="parent" location="start">Clear workspace</v-tooltip>
      </v-btn>
      <!-- <InstallPwa/> -->
      <!-- theme mode -->

      <!-- Removed change theme button because for now it is breaking some things of ui -->
      <!-- <v-btn v-if="$globalsService.isLoggedIn && showNavControls" small class="mr-0 pa-0 b-light" stacked flat @click="toggleTheme">
        <v-icon color="primary">{{ $globalsService.theme === "dark" ? $icons.sun : $icons.moon }}</v-icon>
        <v-tooltip activator="parent" location="start">Change Theme</v-tooltip>
      </v-btn> -->

      <!-- user's avatar -->
      <div v-if="$globalsService.isLoggedIn && showNavControls" class="align-self-center">
        <v-menu v-model="showMenu" bottom rounded offset-y :close-on-content-click="false">
          <template #activator="{ props }">
            <v-btn icon v-bind="props">
              <v-avatar>
                <v-img :src="$globalsService.loggedInUser.profilePhotoBase64" alt="profile image" />
              </v-avatar>
            </v-btn>
          </template>
          <v-card
            :title="`${$globalsService.loggedInUser.givenName} ${$globalsService.loggedInUser.familyName}`"
            :subtitle="$globalsService.loggedInUser.emailAddress"
            :prepend-avatar="$globalsService.loggedInUser.profilePhotoBase64"
          >
            <v-card-text class="profile-menu">
              <v-row v-if="$globalsService.userRole">
                <v-col cols="6" class="text-center">
                  <v-chip label :color="roleColor" class="role"> {{ $globalsService.userRole.name }} </v-chip>
                </v-col>

                <v-col cols="6">
                  <v-btn
                    data-cy="account-open"
                    color="primary"
                    variant="outlined"
                    class="rounded-lg"
                    size="small"
                    @click="
                      showMenu = false;
                      $router.push({ name: 'Account' });
                    "
                    >Manage account
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider class="my-3" />
              <template
                v-for="item in $globalsService.loggedUsers.filter((u) => u.researcher.id !== $globalsService.loggedInUser.id)"
                :key="item.researcher.id"
              >
                <v-row class="cursor-pointer o-light_gray" @click="changeResearcher(item)">
                  <v-col cols="3" class="text-center">
                    <v-avatar>
                      <v-img :src="item.researcher.profilePhotoBase64" alt="profile image" />
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <h3>{{ `${item.researcher.givenName} ${item.researcher.familyName}` }}</h3>
                    <p class="text-caption mt-1">
                      {{ item.researcher.emailAddress }}
                    </p>
                  </v-col>
                </v-row>
              </template>
              <v-row class="cursor-pointer o-light_gray mt-3" @click="logout()">
                <v-col cols="3" class="text-center">
                  <v-icon color="secondary">{{ $icons.user_add }}</v-icon>
                </v-col>
                <v-col cols="9">
                  <span class="c-indipendence">Sign in with another account</span>
                </v-col>
              </v-row>
              <v-row class="cursor-pointer o-light_gray mt-2" @click="logoutAllAccounts()">
                <v-col cols="3" class="text-center">
                  <v-icon color="secondary">{{ $icons.logout }}</v-icon>
                </v-col>
                <v-col cols="9">
                  <span class="c-indipendence">Sign out of all accounts</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </template>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { useMessageBoxStore, MessageConfig } from "@/store/message-box";
import { Researcher } from "@conduxio/types";
import { ResearcherService } from "@/services/researcher.service";
import { WorkspaceService } from "@/services/workspace.service";

//import InstallPwa from '@/components/pwa/InstallPwa.vue'

export default defineComponent({
  name: "TopBar",
  emits: ["toggleLeft"],

  setup() {
    const researcherService = inject<ResearcherService>("researcherService") as ResearcherService;
    const workspaceService = inject<WorkspaceService>("workspaceService") as WorkspaceService;
    const messageBox = useMessageBoxStore(); // declare the mb's store

    window.stores = { messageBox }; // initialize the mb store

    return {
      researcherService,
      workspaceService,
      messageBox,
    };
  },

  // #region Properties
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    showNavControls(): boolean {
      /*
        Date    2023-06-08
        Author  Simone
        Task    "Navigation controls show during a login or registration" https://app.clickup.com/t/863gyezw6
      **/
      // Returns false if, for some reason, we have to hide navigation controls on the current page
      // This is needed because:
      //  during a login a registration or something like that, the left-bar and the other controls
      //  in the top-bar used to be shown after the API call before the user redirection though.
      //  This makes sure these controls are hidden until a complete redirect.
      switch (this.$route.name) {
        case "RegisterHubspot":
        case "LoginForm":
        case "ParticipantSurvey":
          return false;
        default:
          return true;
      }
    },
    roleColor(): string {
      // returns different colors for different role-leels
      switch (this.$globalsService.userRole.level) {
        case 0:
          return "blue"; // Platform owner
        case 25:
          return "cyan"; // Workspace Owner
        case 50:
          return "red"; // Admin
        case 75:
          return "green"; // Researcher
      }

      return "grey";
    },
  },
  // #endregion Properties

  methods: {
    login() {
      this.$router.push("/auth");
    },
    logout() {
      // logout the user
      this.showMenu = false;
      this.$globalsService.logout();
      this.$router.push("/auth");
    },
    logoutAllAccounts() {
      // logout the user
      this.showMenu = false;
      const msg: MessageConfig = {
        title: "Confirm Logout",
        message: "Are you sure you want to logout from all accounts?",
        icon: this.$icons.logout,
        buttons: [
          {
            key: "cancel",
            text: "Cancel ",
            button: false,
          },
          {
            key: "ok",
            text: "Yes",
            button: true,
          },
        ],
        canCancel: true,
      };

      this.messageBox.show(msg, (result: string) => {
        // MessageBox callback
        if (result === "ok") {
          this.$globalsService.logoutAllAccounts();
          this.$router.push("/auth");
        }
      });
    },
    changeResearcher(item: { researcher: Researcher; token: string; refreshToken: string }) {
      this.showMenu = false;
      this.$globalsService.changeLogin(item);

      this.researcherService.changeLogin(item.researcher).then(() => {
        this.$router.push({ name: "Dashboard" }).then(() => {
          this.$router.go(0);
        });
      });
    },
    clearTestWorkspace() {
      this.workspaceService.clear();
    },
    toggleTheme() {
      this.$globalsService.theme = this.$globalsService.theme === "light" ? "dark" : "light";
    },
  },
  // #endregion Watchers
});
</script>

<style scoped>
.profile-menu {
  min-width: 340px;
}
.v-chip.role {
  font-size: 0.85em;
}
</style>
