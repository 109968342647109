/*
  ./src/static/menu/main-menu
  defines main menu items
**/
import icons from "../icons";
import { globalInstance } from "@/services/globals.service";
import { ref } from "vue";

export default [
  {
    title: "Dashboard",
    url: "/dashboard",
    visible: true,
    icon: icons.dashboard,
  },
  {
    title: "Projects",
    url: "/projects",
    visible: false,
    icon: icons.projects,
  },
  {
    title: "Studies",
    url: "/studies",
    visible: true,
    icon: icons.studies,
  },
  // temporary disabled until we have a zoom account
  {
    title: "My Availability",
    url: "/availability",
    visible: false,
    icon: icons.schedule,
  },
  {
    title: "Account",
    url: "/account",
    visible: true,
    icon: icons.account,
  },
  {
    title: "People",
    visible: false,
    icon: icons.participant,
    children: [
      { title: "Participants", url: "/participants", visible: true },
      { title: "Sessions", url: "/sessions", visible: false },
    ],
  },
  {
    title: "Media",
    url: "/media",
    visible: true,
    icon: icons.media,
  },
  {
    title: "Trash",
    url: "/trash",
    visible: false && ref(globalInstance.hasWorkspacePrivileges(["settings_read"])),
    icon: icons.trash,
    bottom: true,
  },
  {
    title: "Workspace Hub",
    url: "/workspacehub",
    visible: ref(globalInstance.hasWorkspacePrivileges(["settings_read"])),
    icon: icons.settings,
    bottom: true,
  },
];
