/*
  ./src/static/menu/platform-owner-menu
  defines menu items for the Platform Owner
**/
import icons from "../icons";

export default [
  {
    title: "Management",
    visible: true,
    icon: icons.platform_owner,
    children: [
      {
        title: "View Subscriptions",
        url: "/platform-owner/subscriptions",
        visible: true,
      },
    ],
  },
];
