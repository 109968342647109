import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiResearcherV1ParticipantSearchCriteriasGetResponse,
  ConduxApiResearcherV1ParticipantSearchPostRequest,
  ConduxApiResearcherV1ParticipantSearchPostResponse,
  ConduxApiResearcherV1ParticipantSearchInvitePostRequest,
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1SelfServiceInfoGetResponse,
  ConduxApiResearcherV1SelfServiceAIEstimate,
  ConduxApiResearcherV1SelfServiceTimeEstimate,
  ConduxApiResearcherV1SelfServiceSurveyUpdate,
} from "@conduxio/types";

export class FMPService extends ConduxService {
  protected modelNameSingular = "FMP";
  protected modelNamePlural = "FMP";

  public async getSearchCriteria(withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1ParticipantSearchCriteriasGetResponse> {
    if (workspaceId === "") {
      workspaceId = this.globalsService.getWorkspaceId();
    }
    const apiRequest = {
      url: "participant/search-criteria",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      modelName: "FMP search criteria",
    };

    return super.get<ConduxApiResearcherV1ParticipantSearchCriteriasGetResponse>(apiRequest);
  }

  public search(
    request: ConduxApiResearcherV1ParticipantSearchPostRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1ParticipantSearchPostResponse> {
    const apiRequest = {
      url: "participant/search",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: request,
      modelName: "FMP search",
    };

    return super.post<ConduxApiResearcherV1ParticipantSearchPostResponse>(apiRequest);
  }

  public invite(request: any, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {
      url: `participant/search/${request.studyId}/invite`,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: request,
      modelName: "FMP invite",
    };

    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }

  public updateFmpStudy(request: any, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SelfServiceSurveyUpdate> {
    console.log("updating study on fmp");
    console.log("fmp update obj", request);
    const apiRequest = {
      url: `self-service/survey-update/${request.id}`,
      object: request,
      modelId: request.id,
      workspaceId: workspaceId,
      withoutWorkspaceId: withoutWorkspaceId,
      modelName: "FMP update study",
    };

    return super.put<ConduxApiResearcherV1SelfServiceSurveyUpdate>(apiRequest);
  }

  public stringToSearchCriteria(request: { string: string }, withoutWorkspaceId = false, workspaceId = ""): Promise<any> {
    const apiRequest = {
      url: "participant/search-criteria/string-conversion/",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: request,
      modelName: "FMP search criteria string conversion",
    };

    return super.post<any>(apiRequest);
  }

  public getMimIncentive(studyId: string): Promise<ConduxApiResearcherV1SelfServiceAIEstimate> {
    // researcher/v1/study-v2/survey/{id}/ai-analysis/expert-score
    const url = `self-service/ai-estimate/${studyId}`;
    const apiRequest = { url: url, withoutWorkspaceId: false, workspaceId: "" };

    return super.get<ConduxApiResearcherV1SelfServiceAIEstimate>(apiRequest);
  }

  public getMinIncentiveFromDuration(duration: any, numberOfFilers: number): Promise<ConduxApiResearcherV1SelfServiceTimeEstimate> {
    const url = `self-service/time-estimate`;
    const apiRequest = { url: url, withoutWorkspaceId: false, workspaceId: "", object: { estimatedTime: duration, numberOfFilters: numberOfFilers } };

    return super.post<ConduxApiResearcherV1SelfServiceTimeEstimate>(apiRequest);
  }

  public payParticipants(request: any): Promise<any> {
    const url = "self-service/charge-campaign";

    const apiRequest = {
      url: url,
      withoutWorkspaceId: false,
      workspaceId: "",
      object: request,
    };

    return super.post<any>(apiRequest);
  }

  public participantSearch(request: any, withoutWorkspaceId = false, workspaceId = ""): Promise<any> {
    const apiRequest = {
      url: "participant/search-criteria/search-participant",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: request,
      modelName: "FMP search criteria string conversion",
    };
    return super.post<any>(apiRequest);
  }

  public participantSearchCriteriaScreener(id: string): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: "participant/search-criteria/screener/" + id };

    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }

  public getStudyFmpData(id: string): Promise<ConduxApiResearcherV1SelfServiceInfoGetResponse> {
    const apiRequest = { url: `self-service/info-get/${id}`, withoutWorkspaceId: false, workspaceId: "" };

    return super.get<ConduxApiResearcherV1SelfServiceInfoGetResponse>(apiRequest);
  }
}
