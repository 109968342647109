import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1ResearcherCheckPostBody,
  ConduxApiResearcherV1ResearcherLoginPostResponse,
  ConduxApiResearcherV1ResearcherDecryptTokenPostBody,
  ConduxApiResearcherV1ResearcherDecryptTokenPostResponse,
  ConduxApiResearcherV1ResearcherSubscribePostBody,
} from "@conduxio/types";

export class AuthService extends ConduxService {
  /**
   * Decrypts an invitation token
   * @param request a ConduxApiResearcherV1ResearcherDecryptTokenPostBody object
   * @returns workspaceId, workspaceName, workspaceLogo and allowed_domains
   */
  decryptToken(request: ConduxApiResearcherV1ResearcherDecryptTokenPostBody): Promise<ConduxApiResearcherV1ResearcherDecryptTokenPostResponse> {
    const apiRequest = { url: "invite-decrypt", object: request, modelName: "token decryption" };
    return super.post<ConduxApiResearcherV1ResearcherDecryptTokenPostResponse>(apiRequest);
  }

  /**
   * Checks if the given emailAddress is already in use
   * @param emailAddress the email address to check
   * @returns the response, that's a 404 if the email is not in use, or a 200 if it is
   */
  checkEmail(emailAddress: string): Promise<ConduxApiResearcherV1ResearcherCheckPostBody> {
    const apiRequest = { url: this.modelNameSingular + "check", object: { emailAddress } };
    return super.post<ConduxApiResearcherV1ResearcherCheckPostBody>(apiRequest);
  }

  /**
   * Subscribes a researcher to a workspace
   * @param request
   * @returns
   */
  public subscribe(request: ConduxApiResearcherV1ResearcherSubscribePostBody): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: "subscribe", object: request, modelName: "subscription", withoutWorkspaceId: true };
    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }

  sendTemporaryPassword(username: string): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "researcher/password-reset?username=" + username };

    return super.get<ConduxApiCommonV1Response>(apiRequest);
  }

  checkTemporaryPassword(username: string, temporaryPassword: string): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "researcher/check-temporary-password", object: { username, temporaryPassword } };

    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }

  passwordReset(data: { username: string; temporaryPassword: string; newPasswordHash: string }): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "researcher/password-reset", object: { ...data } };

    return super.put<ConduxApiCommonV1Response>(apiRequest, true);
  }

  login(data: { username: string; passwordHash: string }): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNamePlural + "researcher/login", object: { ...data } };

    return super.post<ConduxApiResearcherV1ResearcherLoginPostResponse>(apiRequest);
  }

  register(data: {
    first_name: string;
    last_name: string;
    email: string;
    workspace_name: string;
    password: string;
    customerId: string;
    cardId: string;
    paymentMethodId: string;
  }): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNamePlural + "researcher/user-post", object: { ...data } };

    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }

  checkOtp(otp: string, idToken: string): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNamePlural + "researcher/validate-2fa", object: { otp } };

    return super.postWithToken<ConduxApiCommonV1Response>(apiRequest, idToken as any);
  }
}
