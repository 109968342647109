import { ConduxService } from "@/services/condux.service";
import { ConduxApiCommonV1Response, ConduxApiResearcherV1ParticipantSearchStatusGetResponse } from "@conduxio/types";

export class StudyV2Service extends ConduxService {
  protected modelNameSingular = "study-v2";
  protected modelNamePlural = "studies-v2";

  public read(id: string, withoutWorkspaceId = false, workspaceId = "", include = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, include: include };

    const ret = super.get<ConduxApiCommonV1Response>(apiRequest);

    return ret;
  }

  public getParticipationStatus(
    id: string,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1ParticipantSearchStatusGetResponse> {
    // GET researcher/v1/study-v2/participation-status/{id}
    const apiRequest = {
      url: this.modelNameSingular + "/participation-status/" + id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      modelId: id,
    };

    return super.get<ConduxApiResearcherV1ParticipantSearchStatusGetResponse>(apiRequest);
  }
}
