import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1SurveyGetResponse,
  ConduxApiResearcherV1SurveyPostResponse,
  ConduxApiResearcherV1SurveyPutResponse,
  ConduxApiResearcherV1SurveysGetRequest,
  ConduxApiResearcherV1SurveysGetResponse,
  SurveyV3,
  SurveyHelper,
  ConduxApiResearcherV1SurveyStudyGetResponse,
  ConduxApiResearcherV1SurveyStudiesGetResponse,
} from "@conduxio/types";

export class SurveyService extends ConduxService {
  protected modelNameSingular = "survey";
  protected modelNamePlural = "surveys";

  public read(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SurveyGetResponse> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId };

    return super.get<ConduxApiResearcherV1SurveyGetResponse>(apiRequest);
  }

  public readAll(
    request: ConduxApiResearcherV1SurveysGetRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1SurveysGetResponse> {
    const apiRequest = { url: this.modelNameSingular, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, request: request };

    return super.get<ConduxApiResearcherV1SurveysGetResponse>(apiRequest);
  }

  public create(model: SurveyV3, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SurveyPostResponse> {
    model.workspaceId = this.globalsService.getWorkspaceId();
    const validationResult = SurveyHelper.validate(model, false, 3);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SurveyPostResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error creating Survey, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1SurveyPostResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { survey: validationResult.survey },
    };

    return super.post<ConduxApiResearcherV1SurveyPostResponse>(apiRequest);
  }

  public update(model: SurveyV3, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SurveyPutResponse> {
    model.workspaceId = this.globalsService.getWorkspaceId();
    const validationResult = SurveyHelper.validate(model, false, 3);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SurveyPutResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error updating Survey, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1SurveyPutResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular + "/" + model.id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { survey: validationResult.survey },
      modelId: model.id,
    };

    return super.put<ConduxApiResearcherV1SurveyPutResponse>(apiRequest);
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };

    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }

  public getScreeners(): Promise<ConduxApiResearcherV1SurveysGetResponse> {
    const apiRequest = {
      url: "screener",
    };

    return super.get<ConduxApiResearcherV1SurveysGetResponse>(apiRequest);
  }
}
