<template>
  <template v-if="getPlansCanShow">
    <slot></slot>
  </template>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, toRef } from "vue";

const instance = getCurrentInstance()!;
const { $globalsService } = instance.proxy!;

const props = defineProps({
  basic: { type: Boolean, default: false },
  standard: { type: Boolean, default: false },
  enterprise: { type: Boolean, default: true },
});

const basic = toRef(props, "basic");
const standard = toRef(props, "standard");
const enterprise = toRef(props, "enterprise");

const getPlansCanShow = computed(() => {
  const result: string[] = [];

  if (basic.value) result.push("basic");
  if (standard.value) result.push("standard");
  if (enterprise.value) result.push("enterprise");

  const planInGlobal = $globalsService.workspaceSubscriptionPlan !== null ? $globalsService.workspaceSubscriptionPlan.name.toLowerCase() : "unknown";

  return result.includes(planInGlobal);
});
</script>
