import { ConduxService } from "@/services/condux.service";

import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1Generate2FAPostResponse,
  ConduxApiResearcherV1Validate2FAPostRequest,
} from "@conduxio/types";

export class MFAService extends ConduxService {
  protected modelNameSingular = "researcher";
  protected modelNamePlural = "researchers";

  /**
   * Validate2FA: Validate the 2FA code
   * @param body a ConduxApiResearcherV1Validate2FAPostRequest object including otp
   * @returns a ConduxApiCommonV1Response object
   */
  public async validate2FA(body: ConduxApiResearcherV1Validate2FAPostRequest, idToken: string): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/validate-2fa", withoutWorkspaceId: true, object: body, modelName: "login" };

    return super.postWithToken<ConduxApiCommonV1Response>(apiRequest, idToken).then(async (response) => {
      if (response.status === "OK") {
        return response;
      } else {
        return {
          status: "ERROR",
          errorMessage: response.errorMessage,
        } as ConduxApiCommonV1Response;
      }
    });
  }

  public generate(): Promise<ConduxApiResearcherV1Generate2FAPostResponse> {
    const apiRequest = { url: this.modelNameSingular + "/generate-2fa", withoutWorkspaceId: true, modelName: "login" };

    return super.post<ConduxApiResearcherV1Generate2FAPostResponse>(apiRequest);
  }

  public activate(workspaceId: string, data: { secret: string; otp: string }): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/activate-2fa", workspaceId, modelName: "login", object: { ...data } };

    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }

  public deactivate(workspaceId: string, data: { otp: string }): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/deactivate-2fa", workspaceId, modelName: "login", object: { ...data } };

    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }
}
