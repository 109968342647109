/**
 * platform-owner.service.ts
 * Service for Platform Owner functionality
 */
import { ConduxPlatformOwnerService } from "./conduxPlatformOwner.service";
import { ConduxApiPlatformOwnerV1SubscriptionsGetResponse } from "@conduxio/types";
// import { PlatformOwnerSubscription } from "@conduxio/types/lib/types/platform-owner";

export class PlatformOwnerService extends ConduxPlatformOwnerService {
  public getSubscriptions(workspaceId = ""): Promise<ConduxApiPlatformOwnerV1SubscriptionsGetResponse> {
    const apiRequest = { url: "subscriptions", withoutWorkspaceId: false, workspaceId: workspaceId };

    return super.get<ConduxApiPlatformOwnerV1SubscriptionsGetResponse>(apiRequest);
  }
}
