// import { ConduxService } from "./condux.service";
import { ParticipantStatus, ConduxApiCommonV1Response } from "@conduxio/types";
import { ConduxParticipantService } from "./conduxParticipant.service";

export interface ICheckParticipant {
  fmpStudyId: string;
  participantId: string;
}

export interface IUpdateParticipantStatus {
  studyId: string;
  forScreener: boolean;
  statusName: "Invited" | "Completed" | "NoShow" | "Cancelled" | "ClientCancelled" | "NotStarted" | "Qualified" | "NotQualified" | "Started";
  recruitId: string;
}

export class FmpParticipantStudyService extends ConduxParticipantService {
  protected modelNameSingular = "participant";
  protected modelNamePlural = "participant";

  // POST: participant/v1/participant/participant-check/
  public check(request: ICheckParticipant): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/participant-check", object: request };

    return super.post(apiRequest);
  }

  // POST: participant/v1/participant/participant-status/
  public updateStatus(request: IUpdateParticipantStatus): Promise<ParticipantStatus> {
    const apiRequest = { url: this.modelNameSingular + "/participant-status", object: request };

    return super.post(apiRequest);
  }
}
