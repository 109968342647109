import { ConduxService } from "@/services/condux.service";

import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1ResearcherFederatedLoginPostBody,
  ConduxApiResearcherV1ResearcherGetResponse,
  ConduxApiResearcherV1ResearcherLoginPostBody,
  ConduxApiResearcherV1ResearcherLoginPostResponse,
  ConduxAPIResearcherV1ResearcherPasswordPutBody,
  ConduxApiResearcherV1ResearcherPasswordResetGetRequest,
  ConduxApiResearcherV1ResearcherPasswordResetPutBody,
  ConduxApiResearcherV1ResearcherPostResponse,
  ConduxApiResearcherV1ResearcherPutResponse,
  ConduxApiResearcherV1ResearchersGetRequest,
  ConduxApiResearcherV1ResearchersGetResponse,
  ConduxApiResearcherV1ResearcherProfilePutBody,
  ConduxApiResearcherV1ResearcherPasswordResetPostBody,
  Researcher,
  Workspace,
  Role,
  UsersHelper,
  ConduxApiPlatformOwnerV1SubscriptionGet,
  ConduxApiPlatformOwnerV1SubscriptionPut,
} from "@conduxio/types";

export class ResearcherService extends ConduxService {
  protected modelNameSingular = "researcher";
  protected modelNamePlural = "researchers";

  /**
   * Login
   * @param body a ConduxApiResearcherV1ResearcherLoginPostBody object including username and passwordHash
   * @returns a ConduxApiResearcherV1ResearcherLoginPostResponse object including researcher, idToken, refreshToken, serverTimestamp, requires2FA
   */
  public async login(body: ConduxApiResearcherV1ResearcherLoginPostBody): Promise<ConduxApiResearcherV1ResearcherLoginPostResponse> {
    const apiRequest = { url: this.modelNameSingular + "/login", withoutWorkspaceId: true, object: body, modelName: "login" };

    return super.post<ConduxApiResearcherV1ResearcherLoginPostResponse>(apiRequest).then(async (response) => {
      if (response.status === "OK") {
        return response;
      } else {
        return {
          status: "ERROR",
          errorMessage: response.errorMessage,
        } as ConduxApiResearcherV1ResearcherLoginPostResponse;
      }
    });
  }

  public async federatedSignIn(
    body: ConduxApiResearcherV1ResearcherFederatedLoginPostBody
  ): Promise<ConduxApiResearcherV1ResearcherLoginPostResponse> {
    const apiRequest = { url: this.modelNameSingular + "/federated-login", withoutWorkspaceId: true, object: body, modelName: "federated login" };

    return super.post<ConduxApiResearcherV1ResearcherLoginPostResponse>(apiRequest).then(async (response) => {
      if (response.status === "OK") {
        return response;
      } else {
        return {
          status: "ERROR",
          errorMessage: response.errorMessage,
        } as ConduxApiResearcherV1ResearcherLoginPostResponse;
      }
    });
  }

  public async changeLogin(researcher: Researcher): Promise<void> {
    super.checkTokenRefresh();
    await this.setGlobalServiceData(researcher);
  }

  /**
   * LoginDone: to be called when the login is done and successful
   * @param researcher the logged in researcher
   * @param idToken the loggedin researcher's token
   * @param refreshToken the loggedin researcher's refresh token
   * @param serverTimestamp the server timestamp
   */
  public async LoginDone(
    researcher: Researcher,
    idToken: string,
    refreshToken: string,
    serverTimestamp: number,
    keepLogged: boolean,
    requires2FA: boolean
  ): Promise<void> {
    console.log("LoginDone", researcher, idToken, refreshToken, serverTimestamp, keepLogged, requires2FA);
    this.globalsService.loggedInUser = researcher;
    this.globalsService.userIdToken = idToken;
    this.globalsService.userRefreshToken = refreshToken;
    this.globalsService.userHas2FA = requires2FA;
    await this.setGlobalServiceData(researcher, undefined);
    const serverTimestampDifference = Date.now() - (serverTimestamp as number);
    this.globalsService.serverTimestampDifference = serverTimestampDifference;

    // Save the user in the loggedUsers list
    if (keepLogged) {
      const foundIndex = this.globalsService.loggedUsers.findIndex((u) => u.researcher.id === this.globalsService.loggedInUser.id);
      if (foundIndex < 0) {
        // Adds the user to the loggedUsers list
        this.globalsService.loggedUsers.push({
          researcher: this.globalsService.loggedInUser,
          token: this.globalsService.userIdToken,
          refreshToken: this.globalsService.userRefreshToken,
        });
      } else {
        // Refresh the user within loggedUsers list
        this.globalsService.loggedUsers[foundIndex].researcher = this.globalsService.loggedInUser;
        this.globalsService.loggedUsers[foundIndex].token = this.globalsService.userIdToken;
        this.globalsService.loggedUsers[foundIndex].refreshToken = this.globalsService.userRefreshToken;
      }
    }
  }

  private async setGlobalServiceData(researcher: Researcher, workspaceId = undefined as string | undefined): Promise<void> {
    // Auto select workspace role if there is only one role - majority of users
    this.globalsService.userWorkspaces = [];
    this.globalsService.workspaceRoles = [];

    // New API: the new login API provides also the researchers's user_assigned_roles with roles and workspaces inside the researcher
    // Therefore, we don't need to call other APIs to get them
    for (const assignedRole of researcher.userAssignedRoles) {
      // TODO: remove "eslint-disable" and ! when we have the right types
      // eslint-disable-next-line
      // @ts-ignore
      const workspace: Workspace = assignedRole.workspace!;
      const role: Role = assignedRole.role!;

      role.workspaceId = workspace.id;
      this.globalsService.userWorkspaces.push(workspace);
      this.globalsService.workspaceRoles.push(role);
    }

    this.globalsService.isWorkspaceSet = true;
    if (this.globalsService.userWorkspaces.length > 0) {
      // if i force a workspace from queryurl
      const indexWorkspace = this.globalsService.userToWorkspaceId.findIndex((sw) => sw.userId === this.globalsService.loggedInUser.id);

      if (workspaceId && this.globalsService.userWorkspaces.find((ws) => ws.id === workspaceId)) {
        this.globalsService.userWorkspace = this.globalsService.userWorkspaces.find((ws) => ws.id === workspaceId) as Workspace;
        //this part is for update the array of userToWorkspaceId
        if (indexWorkspace >= 0) {
          this.globalsService.userToWorkspaceId[indexWorkspace].workspaceId = workspaceId;
        } else {
          this.globalsService.userToWorkspaceId.push({
            userId: this.globalsService.loggedInUser.id as string,
            workspaceId: workspaceId,
          });
        }
      } else {
        // if i have a selected workspace in local storage
        if (indexWorkspace >= 0) {
          this.globalsService.userWorkspace = this.globalsService.userWorkspaces.find(
            (w) => w.id === this.globalsService.userToWorkspaceId[indexWorkspace].workspaceId
          ) as Workspace;

          //if the workspace in local storage is old/wrong
          if (!this.globalsService.userWorkspace) {
            this.globalsService.userWorkspace = this.globalsService.userWorkspaces[0];
            this.globalsService.userToWorkspaceId[indexWorkspace].workspaceId = this.globalsService.userWorkspace.id as string;
            this.globalsService.isWorkspaceSet = false;
          }
        } else {
          // if i dont have a selected workspace in local storage
          this.globalsService.userWorkspace = this.globalsService.userWorkspaces[0];
          this.globalsService.userToWorkspaceId.push({
            userId: this.globalsService.loggedInUser.id as string,
            workspaceId: this.globalsService.userWorkspace.id as string,
          });
          // if there are multiple workspace
          if (this.globalsService.userWorkspaces.length !== 1) {
            this.globalsService.isWorkspaceSet = false;
          }
        }
      }

      // Selects the role in the active workspace
      this.globalsService.userRole = this.globalsService.workspaceRoles.find((r) => r.workspaceId === this.globalsService.userWorkspace.id) as Role;

      this.globalsService.userRole = this.globalsService.workspaceRoles.find((r) => r.workspaceId === this.globalsService.userWorkspace.id) as Role;
      // // adds the subscription plan, if no plan is tied to workspace, it will be "null"
      this.globalsService.workspaceSubscriptionPlan =
        (this.globalsService.userWorkspaces.find((ws) => ws.id === this.globalsService.userRole!.workspaceId) as any).workspacePlan ?? null;

      // Set the workspace plan
    } else {
      throw new Error("Workspace empty for this user");
    }
  }

  public create(researcher: Researcher, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1ResearcherPostResponse> {
    const validationResult = UsersHelper.validateResearcher(researcher);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1ResearcherPostResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error creating User, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1ResearcherPostResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { researcher: validationResult.researcher },
    };

    return super.post<ConduxApiResearcherV1ResearcherPostResponse>(apiRequest);
  }

  public read(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1ResearcherGetResponse> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId };

    return super.get<ConduxApiResearcherV1ResearcherGetResponse>(apiRequest);
  }

  public readAll(
    request: ConduxApiResearcherV1ResearchersGetRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1ResearchersGetResponse> {
    const apiRequest = { url: this.modelNameSingular, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, request: request };

    return super.get<ConduxApiResearcherV1ResearchersGetResponse>(apiRequest);
  }

  /**
   * This function will check if the updated user is the logged-in user an update the globalsService variable accordingly
   *
   * @param researcher
   */
  public update(researcher: Researcher, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1ResearcherPutResponse> {
    const validationResult = UsersHelper.validateResearcher(researcher);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1ResearcherPutResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error updating User, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1ResearcherPutResponse);
      });
    }

    return new Promise<ConduxApiResearcherV1ResearcherPutResponse>(() => {
      const apiRequest = {
        url: this.modelNameSingular + "/" + researcher.id,
        withoutWorkspaceId: withoutWorkspaceId,
        workspaceId: workspaceId,
        object: { researcher: validationResult.researcher },
        modelId: researcher.id,
      };

      return super.put<ConduxApiResearcherV1ResearcherPutResponse>(apiRequest).then((response) => {
        if (response.status === "OK") {
          if (response.researcher.id === this.globalsService.loggedInUser.id) {
            this.globalsService.loggedInUser = response.researcher;
          }
        }
      });
    });
  }
  public remove(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };

    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }
  //update profile in account page
  public profileUpdate(request: ConduxApiResearcherV1ResearcherProfilePutBody): Promise<ConduxApiResearcherV1ResearcherPutResponse> {
    const apiRequest = { url: this.modelNameSingular + "/profile", object: request, modelId: "fake" };

    return super.put<ConduxApiResearcherV1ResearcherPutResponse>(apiRequest);
  }
  //update password in account page
  public passwordUpdate(body: ConduxAPIResearcherV1ResearcherPasswordPutBody): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/password", object: body, modelId: "fake", modelName: "password" };

    return super.put<ConduxApiCommonV1Response>(apiRequest);
  }
  //reset the password when not logged in PasswordForgot page
  public passwordResetGet(request: ConduxApiResearcherV1ResearcherPasswordResetGetRequest): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/password-reset", request: request, modelName: "password", withoutWorkspaceId: true };

    return super.get<ConduxApiCommonV1Response>(apiRequest);
  }
  //set the new password when not logged in PasswordResetpage
  public passwordResetPut(body: ConduxApiResearcherV1ResearcherPasswordResetPutBody): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {
      url: this.modelNameSingular + "/password-reset",
      object: body,
      modelId: "fake",
      modelName: "password reset",
      withoutWorkspaceId: true,
    };

    return super.put<ConduxApiCommonV1Response>(apiRequest);
  }
  //reset the password of other user when logged in AccessUser Page
  public passwordResetPost(body: ConduxApiResearcherV1ResearcherPasswordResetPostBody): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/password-reset", object: body, modelName: "password reset" };

    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }

  /**
   * Returns the subscription for current Workspace ID
   * researcher/v1/subscription?workspace_id={workspaceId}
   * @returns Promise<any>
   */
  public getSubscription(): Promise<ConduxApiPlatformOwnerV1SubscriptionGet> {
    // TODO: define the return type
    const url = `subscription`;
    const apiRequest = { url: url, withoutWorkspaceId: false, workspaceId: `` };
    return super.get<ConduxApiPlatformOwnerV1SubscriptionGet>(apiRequest);
  }

  /**
   * Returns the subscription for current Workspace ID
   * researcher/v1/subscription?workspace_id={workspaceId}
   * @returns Promise<any>
   */
  public disableSubscription(): Promise<ConduxApiPlatformOwnerV1SubscriptionPut> {
    // TODO: define the return type
    const url = `unsubscribe`;
    const apiRequest = { url: url, withoutWorkspaceId: false };
    return super.put<ConduxApiPlatformOwnerV1SubscriptionPut>(apiRequest, true);
  }
}

let instance: ResearcherService;

function setResearcherServiceInstance(researcherServiceInstance: ResearcherService): void {
  instance = researcherServiceInstance;
}

function getResearcherServiceInstance(): ResearcherService {
  /*
    Date    2023-06-15
    Author  Simone
    Task    "Retore and Improve refreshToken functionallity" https://app.clickup.com/t/863gzuva1
    Desc    in the previous code, this function was called by the router/index.ts,
            and this was probabily meant to refer the ResearcherService Globally.
            After 2023-05-13 this cannot work, and we're not able to identify the reason.
            What we know is the getResearcherServiceInstance returns an "undefined" object.
            Of corse, there's more code we've lost somewhere else. The problem is "where???"
            Currently, this function isn't used anymore - from any where in the frontend
  **/
  return instance;
}

export { getResearcherServiceInstance, setResearcherServiceInstance };
